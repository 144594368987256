import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import AnalyticsLeftColumn from "../../components/AnalyticsLeftColumn";
import AnalyticsRightColumn from "../../components/AnalyticsRightColumn";
import { useAlternateLangs } from "../../components/Hreflangs";

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/analytics"
);


const Analytics = ({ location }) => { 
  return (<Layout location={location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Handledningar om webbanalys, GA & Google Tag Manager"
        lang="se"
        description="Lär dig grunderna och avancerade koncept inom webbanalys, Google Analytics & GTM med steg-för-steg handledningar. Förbättra din marknadsföringsprestanda med handlingsbara insikter!"
        image="homepage-screenshot"
        alternateLangs={alternateLangs}
        canonical="/se/analys"
      />
      <MainFrontpage>
        <AnalyticsLeftColumn />
        <AnalyticsRightColumn />
      </MainFrontpage>
    </React.Fragment>

  </Layout>
  );
};

export default React.memo(Analytics);
